import React from "react";
import { Alert, Row } from "react-bootstrap";

export default ({ show }) => {
  if (show) {
    return (
      <Row>
        <Alert bsStyle="danger">
          There is an issue with your settings configuration.
          <a
            // href="https://github.com/aws-samples/amazon-rekognition-engagement-meter/blob/master/CONTRIBUTING.md#working-with-the-web-ui"
            href="https://xcape.in"
            rel="noopener noreferrer"
            target="_blank"
          >
            this guide
          </a>
          .
        </Alert>
      </Row>
    );
  }
  return "";
};
